<template>
<div v-if="isTestAccount">
  <div class="bt_artist">
    <router-link :to="{name: 'artist-detail', params:{artist: artist.id}}">
      <img v-if="artist.image" :src="artist.image | imageUrl" alt="マスター選択画像">
      <div class="artist_title">{{ artist.talk_room.name }}
        <div class="artist_date">最終トーク：
          <span v-if="lastMessage">{{ lastMessage.send_at }}</span>
          <span v-else>----</span>
        </div>
      </div>
    </router-link>
  </div>

</div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'ArtistListLink',
  components: {},
  props: {'artist': Object},
  data () {
    return {
      lastMessage: null,
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      const lastMessage = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${this.artist.talk_room.id}/last`
      );
      if (lastMessage.data.message) {
        this.lastMessage = lastMessage.data.message;
      }
    },
  },
  computed: {
    isTestAccount() {
      return this.artist.talk_room.is_secret !== 1;
  }
}
}
</script>

<style scoped>
.master-image {
  width: 200px;
  height: 200px;
}
.master-image image{
  width: 100%;
  height: 100%;
}
</style>
